<template>
  <div class="app-container">
    <div class="top_select">
      <el-button
        icon="RefreshRight"
        size="small"
        style="margin-right: 5px"
        @click="getList()"
      ></el-button>
    </div>
    <el-table
      :data="list"
      style="width: 100%"
      border
      :height="contentStyleObj"
      v-loading="loading"
    >
      <template #empty>
        <el-empty :image-size="150" description="没有数据"></el-empty>
      </template>
      <el-table-column type="index" label="序号" width="55" align="center" />
      <el-table-column prop="name" label="任务名称" align="center" />
      <el-table-column align="right" label="Ready">
        <template #default="scope">
          <a @click="showDetail(scope.row, '1')">{{
            scope.row.readyList?.length
          }}</a>
        </template>
      </el-table-column>
      <el-table-column align="right" label="Unacked">
        <template #default="scope">
          <a @click="showDetail(scope.row, '2')">
            {{ scope.row.unAckedList?.length }}
          </a>
        </template>
      </el-table-column>
    </el-table>
  </div>
  <el-drawer
    :title="title"
    v-model="drawer"
    :direction="direction"
    destroy-on-close
    size="77%"
  >
    <div class="top_select">
      <el-button
        icon="RefreshRight"
        size="small"
        style="margin-right: 5px"
        @click="freshList()"
      ></el-button>
      <el-radio-group
        v-model="showType"
        style="margin-left: 5px"
        size="small"
        @change="typeChange"
      >
        <el-radio-button label="1">Ready</el-radio-button>
        <el-radio-button label="2">Unacked</el-radio-button>
      </el-radio-group>
    </div>
    <el-table
      :data="showList"
      border
      :height="contentStyleObj"
      style="width: 100%"
      stripe
    >
      <template #empty>
        <el-empty :image-size="150" description="没有数据"></el-empty>
      </template>
      <el-table-column type="index" label="序号" width="55" align="center" />
      <el-table-column prop="Name" label="名称" />
      <el-table-column prop="UUID" label="UUID" />
      <el-table-column prop="Value" label="value" />
      <el-table-column prop="WorkId" label="workId" />
      <el-table-column align="center" label="操作" width="70" fixed="right">
        <template #default="scope">
          <el-button size="small" plain type="danger" @click="deleteReady(scope.row)" v-if="showType == '1'">
            删除
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <div class="pagination">
      <qzf-pagination
        v-show="total > 0"
        :total="total"
        v-model:page="page"
        v-model:limit="limit"
        @pagination="changeLimit(showType)"
      />
    </div>
  </el-drawer>
</template>

<script setup>
import { ref, onMounted, getCurrentInstance } from "vue";
import { getLxMqList , delLxMqList } from "@/api/org";
const { proxy } = getCurrentInstance();
const list = ref([]);
const loading = ref(false);
const contentStyleObj = ref({});
const drawer = ref(false);
const showList = ref([]);
const title = ref("");
const readyInfo = ref([]);
const unAckedInfo = ref([]);
const currentName = ref("");
const page = ref(1);
const limit = ref(20);
const showType = ref("1");
const total = ref(0);
onMounted(() => {
  contentStyleObj.value = proxy.$getHeight(180);
  getList();
});
const getList = () => {
  loading.value = true;
  getLxMqList({}).then((res) => {
    loading.value = false;
    if (res.data.msg == "success") {
      list.value = res.data.data.list ? res.data.data.list : [];
      if (currentName.value) {
        list.value.map((v) => {
          if (v.name == currentName.value) {
            readyInfo.value = v.readyList;
            unAckedInfo.value = v.unAckedList;
            typeChange('fresh')
          }
        });
      }
    }
  });
};
const showDetail = (row, flag) => {
  readyInfo.value = row.readyList;
  unAckedInfo.value = row.unAckedList;
  title.value = flag == "1" ? "Ready" : "unAcked";
  currentName.value = row.name;
  showType.value = flag;
  typeChange();
  drawer.value = true;
};
const changeLimit = (flag) => {
  if (flag == "1") {
    let arr = readyInfo.value;
    showList.value = arr
      ? arr.slice((page.value - 1) * limit.value, page.value * limit.value)
      : [];
  } else {
    let arr = unAckedInfo.value;
    showList.value = arr
      ? arr.slice((page.value - 1) * limit.value, page.value * limit.value)
      : [];
  }
};
const freshList = () => {
  getList();
  changeLimit(showType.value);
};
const typeChange = (isFresh) => {
  if(!isFresh){
    page.value = 1;
  }
  showList.value =
    showType.value == "1"
      ? proxy.$qzfCopy(readyInfo.value)
      : proxy.$qzfCopy(unAckedInfo.value);
  total.value =
    showType.value == "1" ? (readyInfo.value != null ?readyInfo.value.length:[]) : (unAckedInfo.value != null ?unAckedInfo.value.length:[]);
  changeLimit(showType.value);
};
const deleteReady = (row) => {
  proxy.$confirm("是否确定删除?", "提示", {
    confirmButtonText: "确定",
    cancelButtonText: "取消",
    type: "warning",
  }).then(() => {
    let param = { name: row.Name ,uuid:row.UUID}
    loading.value = true;
    delLxMqList(param).then(res => {
      loading.value = false;
      if (res.data.msg == "success") {
        getList()
      }
    })
  })
  
};
</script>

<style scoped lang="scss">
.top_select {
  margin-bottom: 10px;
  display: flex;
  align-items: center;
}
a {
  cursor: pointer;
  text-decoration: underline;
}
</style>
